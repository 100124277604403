import URLs from "../../config/URLs";
import { HrefSpacing, Name, NavbarStyle, SideContent } from "./styles"

const Navbar = ()=> {
    return (
        <NavbarStyle>
            <Name variant='h3' fontFamily='Bebas Neue'>FACUNDO FERREIRA</Name>
            <SideContent>
                <HrefSpacing href={URLs.ITCH} target="_blank">Itch.io</HrefSpacing>
                <HrefSpacing href={URLs.TWITTER} target="_blank">Twitter</HrefSpacing>
                <HrefSpacing href={URLs.LINKEDIN} target="_blank">LinkedIn</HrefSpacing>
            </SideContent>
        </NavbarStyle>
    )
}

export default Navbar;