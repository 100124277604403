import { useMediaQuery, useTheme } from "@mui/material";
import Highlight from "../../../components/Highlight";
import PageWrapper from "../../../components/PageWrapper";
import { BoxContent, ColumnContent, Container, Paragraph, Title } from "./styled";

const About = ()=> {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <PageWrapper topPadding>
            <Container>
                <BoxContent>
                    <Title variant='h2' color='secondary'>About Me</Title>
                    <Paragraph variant='p' component='p'>My name is <Highlight>Facundo Ferreira La Valle</Highlight> and I'm an aspiring Game Designer with <Highlight>7 years of programming experience</Highlight> under my wing.</Paragraph>
                    <Paragraph variant='p' component='p'>I'm a <Highlight>Computer Science Engineer</Highlight> and have been working with <Highlight>Agile Development for 6 years</Highlight>. I believe that <Highlight>good communication is the key</Highlight> to a succesful development process and that the team works best when everyone feels <Highlight>valued</Highlight> and <Highlight>confortable</Highlight> at work.</Paragraph>
                    <Paragraph variant='p' component='p'>I also have a <Highlight>Game Programmer</Highlight> course in Unity.</Paragraph>
                    <Paragraph variant='p' component='p'>Over the years I've designed and coded multiple games in <Highlight>Unity</Highlight> and also plenty of <Highlight>Board Games</Highlight>.</Paragraph>
                    {!isTablet && <Paragraph variant='p' component='p'>The ability of games to derive such a particular experience in the player is what made me keep going back to game design over and over again, and right now, I am ready to offically delve into it's world.</Paragraph>}
                </BoxContent>
                {!isTablet && <ColumnContent>
                    <BoxContent leftSpace>
                        <Title variant='h5' color='secondary' fontFamily='Bebas Neue' >Videogames</Title>
                        <Paragraph variant='p' component='p'>I've been in love with videogames ever since I played Crash Bandicoot in my PS1.</Paragraph>
                        <Paragraph variant='p' component='p'>I've played all sorts of genres over the years but my heart will always be with <Highlight>Strategy</Highlight> and <Highlight>Story Driven</Highlight> games.</Paragraph>
                    </BoxContent>
                    <BoxContent leftSpace>
                        <Title variant='h5' color='secondary' fontFamily='Bebas Neue' topPadding>Boardgames</Title>
                        <Paragraph variant='p' component='p'>Boardgames have evolved a lot over the years. <Highlight>Catan</Highlight> and <Highlight>King of Tokyo</Highlight> are top tier for me since they reward <Highlight>Desition Making</Highlight> and <Highlight>Interacting Between Players</Highlight>.</Paragraph>
                        <Paragraph variant='p' component='p'>Role playing games need a section too. As a <Highlight>Dungeon Master</Highlight> preparing experiences for my players and watching them react to them is extremly rewarding.</Paragraph>
                        <Paragraph variant='p' component='p'>I believe that a good boardgame <Highlight>brings everyone together</Highlight> and involves a good mix of <Highlight>strategy</Highlight> and <Highlight>good old luck</Highlight>.</Paragraph>
                    </BoxContent>
                </ColumnContent>}
            </Container>
        </PageWrapper>
    )
}

export default About;