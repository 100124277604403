import styled from '@emotion/styled'

export const HeroStyled = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: start;
    position: relative;
    overflow: hidden;
`

export const VideoBackground = styled.video`
    position: absolute;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    filter: blur(2px) brightness(0.5);
    z-index: -10;
`

export const HeroContent = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
    padding: 0px 10%;
`