import thumbnail from '../../../../assets/images/Thumbnail.jpg'
import advanced from '../../../../assets/images/Advanced_scene.jpg'
import mouse_discover from '../../../../assets/images/Mouse_discover.png'
import ritual_room from '../../../../assets/images/Ritual_room.png'
import Highlight from '../../../../components/Highlight'
import LinkHref from '../../../../components/LinkHref'

const Mischief = {
    title: 'A Night for Mischief [GAME JAM WINNER]',
    date: '2023',
    duration: '10 days.',
    descriptions: [
        <span>A Night for Mischief is a clicker game created by Denise Chirich and me for the <Highlight><LinkHref href='https://itch.io/jam/clicker-jam-winter-2023/results' target='_blank'>Winter Clicker Jam Winter 2023</LinkHref></Highlight></span>,
        <span>The goal is to help a cat who finds itself alone mess up the house. <Highlight>The more you mess up the place, the more things you will discover!</Highlight></span>,
        <span>For this game, we wanted to present something that seemed <Highlight>simple and cute a first</Highlight>, but as you played made you realise there was something wierd going on.</span>,
        <span>We did this by making the player <Highlight>gain strange items</Highlight> as they progressed, such as candles, creepy mouses and finally a photograph of the cat's owner.</span>,
        <span>Additionally, <Highlight>once the player had gotten a grasp of the basic mechanics</Highlight>, we presented them with the room for the ritual, which sets in the idea even further that something is up.</span>,
    ],
    featuredImage: thumbnail,
    minorImages: [
        mouse_discover,
        advanced,
        ritual_room,
    ],
    tools: ['Unity', 'Photoshop'],
}

export default Mischief