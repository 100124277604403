import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { StyledLink } from '../Link/styled'
import LinkHref from '../LinkHref'

export const NavbarStyle = styled.nav(({ theme }) => `
    padding: 24px 10%;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    z-index: 10;
    background-color: rgb(18 18 18 / 75%);
    background-color: ${theme.palette.background.default};
`)

export const Name = styled(Typography)`
    min-width: 400px;
`

export const SideContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const LinkSpacing = styled(StyledLink)`
    margin-left: 8px;
`
export const HrefSpacing = styled(LinkHref)`
    margin-left: 8px;
`