// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC47XzGqx2Hz9xwwj3P1xBuFmQ8ntALz1A",
  authDomain: "facundo-ferreira.firebaseapp.com",
  projectId: "facundo-ferreira",
  storageBucket: "facundo-ferreira.appspot.com",
  messagingSenderId: "1056885504513",
  appId: "1:1056885504513:web:d808bd0ba2d49a76c85e00",
  measurementId: "G-PHFQ5FG4RF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);