import {  useMediaQuery, useTheme } from "@mui/material";
import { Paragraph, Title } from "../../pages/Home/About/styled";
import { ChipStyled, ContentSide, ContentTop, DateTitle, ImageStyled, MinorImage, MinorVideo, ToolsList, ToolsTitle, VideoStyled, Wrapper } from "./styled";

const ProjectPreview = ({ projectData, ...props })=> {
	const theme = useTheme();
	const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

	const { title, date, duration, descriptions, featuredVideo, featuredImage, minorVideos, minorImages, tools } = projectData;

	return (
		<Wrapper {...props}>
			<ContentTop>
				<ContentSide>
					<Title variant='h3' color='secondary'>{title}</Title>
					<DateTitle fontWeight={600}>{`Date: ${date} - Development Time: ${duration}`}</DateTitle>
					{descriptions.map(d => (
							<Paragraph variant='p' component='p'>{d}</Paragraph>
					))}
					<ToolsTitle variant='h4' color='secondary' fontFamily='Bebas Neue'>Tools</ToolsTitle>
					<ToolsList>
							{tools.map(d => (
									<ChipStyled variant="outlined" label={d}/>
							))}
					</ToolsList>
				</ContentSide>
				<ContentSide>
						{featuredVideo && <VideoStyled src={featuredVideo} muted autoPlay loop/>}
						{featuredImage && <ImageStyled src={featuredImage}/>}
				</ContentSide>
			</ContentTop>
			{!isTablet && <ContentTop padding>
					{minorVideos && minorVideos.map((mv, i) => (
							<MinorVideo src={mv} key={i} muted autoPlay loop/>
					))}
					{minorImages && minorImages.map((mi, i) => (
							<MinorImage src={mi} key={i} />
					))}
			</ContentTop>}
		</Wrapper>
	)
}

export default ProjectPreview;