import { CssBaseline, ThemeProvider } from "@mui/material";
import './config/firebase'
import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import theme from "./config/theme";
import "./index.css";
import Home from "./pages/Home";

const renderDefaultContent = (content)=> (
  <>
    <Navbar />
    {content}
  </>
)

const router = createBrowserRouter([
  {
    path: "/",
    element: renderDefaultContent(<Home />),
  },
]);

const App = ()=> (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>
)

export default App;
