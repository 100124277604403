import styled from '@emotion/styled'

const LinkHref = styled.a`
    transition: color 0.25s;
    color: inherit;
    &:visited  {
        color: inherit;
    }
    &:active {
        color: inherit;
    }
    &:hover {
        color: #00B6FE;
    }
`

export default LinkHref
