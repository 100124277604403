import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: 'dark',
    secondary: {
      main: '#00B6FE'
    },
    background: {
      default: 'black',
      secondary: '#131313',
    }
  },
  typography: {
    fontFamily: [
      'Roboto',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h2: {
      fontFamily: 'Bebas Neue'
    },
    h3: {
      fontFamily: 'Bebas Neue'
    },
  },
});

export default theme;