import styled from '@emotion/styled'
import { Typography, useMediaQuery } from '@mui/material'

export const Container = styled.section(({ theme }) => {
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    return `
        display: flex;
        flex-direction: ${isTablet ? 'column' : 'row'};
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    `
})
export const ColumnContent = styled.div(() => `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`)
export const BoxContent = styled.div(({ leftSpace, theme }) => {
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    
    return `
        display: flex;
        flex-direction: column;
        max-width: ${!leftSpace && !isTablet && '55%'};
        margin-left: ${leftSpace && !isTablet && '64px'};
    `
})
export const Paragraph = styled(Typography)`
    margin-bottom: 16px;
`
export const Title = styled(Typography)(({ topPadding }) => `
    margin-bottom: 16px;
    margin-top: ${topPadding && '16px'};
`)
