import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
    transition: color 0.25s;
    color: inherit;
    &:visited  {
        color: white !important;
    }
    &:active {
        color: white !important;
    }
    &:hover {
        color: #00B6FE;
    }
`