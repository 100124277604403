import styled from '@emotion/styled'
import { Chip, Typography, useMediaQuery } from '@mui/material'

export const Wrapper = styled.div(() => `
    display: flex;
    flex-direction: column;
    padding-bottom: 64px;
`)

export const DateTitle = styled(Typography)(() => `
    padding-bottom: 16px;
`)

export const ContentTop = styled.div(({ theme, padding }) => {
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    return `
        display: flex;
        flex-direction: ${isTablet ? 'column' : 'row'};
        justify-content: space-between;
        padding: ${padding && '16px'};
        padding-left: 0px;
        padding-bottom: 32px;
    `
})

export const ContentSide = styled.div(({ theme }) => {
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    return `
        display: flex;
        flex-direction: column;
        width: ${isTablet ? '100%' : '47%'};
        padding: 16px;
        padding-left: 0px;
    `
})

export const ToolsTitle = styled(Typography)(()=> `
    margin-bottom: 12px;
`)

export const ToolsList = styled.div(() => `
    display: flex;
    flex-direction: row;
`)

export const ChipStyled = styled(Chip)(() => `
    margin-right: 8px;
`)

export const VideoStyled = styled.video(()=> `
    box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 50%);
`)
export const ImageStyled = styled.img(()=> `
    box-shadow: 0px 0px 20px 15px rgb(0 0 0 / 50%);
`)

export const MinorVideo = styled.video(()=> `
    box-shadow: 0px 0px 10px 7px rgb(0 0 0 / 50%);
    width: 32%;
    transition: transform 0.5s;
    transform-origin: bottom center;
    &:hover {
        transform: scale(1.5, 1.5);
        z-index: 10;
    }
`)

export const MinorImage = styled.img(()=> `
    box-shadow: 0px 0px 10px 7px rgb(0 0 0 / 50%);
    width: 32%;
    transition: transform 0.5s;
    transform-origin: bottom center;
    &:hover {
        transform: scale(1.5, 1.5);
        z-index: 10;
    }
`)