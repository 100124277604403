import WalkingVideo from '../../../../assets/video/Walking_overworld.mp4'
import Tactics from '../../../../assets/video/Tactics.mp4'
import PartyView from '../../../../assets/video/Party_view.mp4'
import PokemonAttack from '../../../../assets/video/Pokemon_attack.mp4'
import Highlight from '../../../../components/Highlight'

const Pokemon = {
    title: 'Pokemon: Hearts Divided',
    date: '2021',
    duration: '1 & half years.',
    descriptions: [
        <span>Pokemon: Hearts Divided is a fan-game about the <Highlight>relationship between humans and pokemon</Highlight>. It's commentary about how the pokemon games present pokemon as both partners but also <Highlight>tools to be used by humans</Highlight>, and how they present themselves as <Highlight>things to collect</Highlight> instead of actual <Highlight>living beings</Highlight>.</span>,
        <span>The game allows the trainer to <Highlight>interact in unique ways with pokemon</Highlight> such as complete misions for them, become their friend before catching them and also catch them against their will.</span>,
        <span><Highlight>Pokemon caught against their will</Highlight> won't always listen to the player and are meant to cast <Highlight>doubts in their actions</Highlight>. For this, the game also takes a <Highlight>more serious tone</Highlight> when it needs to.</span>,
    ],
    featuredVideo: WalkingVideo,
    minorVideos: [
        Tactics,
        PokemonAttack,
        PartyView,
    ],
    tools: ['Unity', 'Asesprite', 'Fungus (Unity)'],
}

export default Pokemon