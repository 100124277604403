import { Typography } from "@mui/material";
import { HeroContent, HeroStyled, VideoBackground } from "./styles"
import BattleStarting from "./../../assets/video/Battle_starting.mp4"

const Hero = ()=> {
    return (
        <HeroStyled>
            <VideoBackground src={BattleStarting} muted autoPlay loop/>
            <HeroContent>
                <Typography variant='h2'>Game Designer &</Typography>
                <Typography variant='h2'>Game Programmer</Typography>
            </HeroContent>
        </HeroStyled>
    )
}

export default Hero;