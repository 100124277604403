import { useMediaQuery, useTheme } from "@mui/material";
import PageWrapper from "../../../components/PageWrapper";
import ProjectPreview from "../../../components/ProjectPreview";
import { Title } from "../About/styled";
import list from "./list";

const Projects = ()=> {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <PageWrapper topPadding variant='secondary'>
            <Title variant='h2' color='secondary'>Projects</Title>
            {list.map((l, i) => (
                <ProjectPreview projectData={l} key={i}/>
            ))}
        </PageWrapper>
    )
}

export default Projects;