import Hero from "../../components/Hero";
import About from "./About";
import Projects from "./Projects";
import { Container } from "./styles";

const Home = ()=> {
    return (
        <Container>
            <Hero />
            <About />
            <Projects />
        </Container>
    )
}

export default Home;